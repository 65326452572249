import { ChangeEvent, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { useWebSocket } from "../../Services/ReactWebSocket";
import Message from "./Message";

export default function MessageViewer() {
  const [, history] = useWebSocket();
  const [shouldShowOutgoing, updateShouldShowOutgoing] = useState(true);
  const [shouldShowLifeCycle, updatedShouldShowLifecycle] = useState(true);

  let filteredHistory = history;

  if (!shouldShowOutgoing) {
    filteredHistory = filteredHistory.filter((item) => {
      if (item.type === "message") {
        return item.direction === "in";
      }
      return true;
    });
  }

  if (!shouldShowLifeCycle) {
    filteredHistory = filteredHistory.filter((item) => {
      return item.type === "message";
    });
  }

  function handleShouldShowOutgoingChange(e: ChangeEvent<HTMLInputElement>) {
    updateShouldShowOutgoing(e.target.checked);
  }

  function handleShouldShowLifeCycleChange(e: ChangeEvent<HTMLInputElement>) {
    updatedShouldShowLifecycle(e.target.checked);
  }

  return (
    <div>
      <div>
        <Label>HISTORY</Label>
      </div>
      <div
        className="border border-gray rounded"
        style={{
          height: "40vh",
          overflowY: "scroll",
        }}
      >
        {filteredHistory.map((message) => (
          <Message {...message} />
        ))}
      </div>
      <div className="my-2">
        <Form inline>
          <FormGroup check inline>
            <Label check>
              <Input
                checked={shouldShowLifeCycle}
                type="checkbox"
                onChange={handleShouldShowLifeCycleChange}
              />{" "}
              Show Lifecycle Messages
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input
                checked={shouldShowOutgoing}
                type="checkbox"
                onChange={handleShouldShowOutgoingChange}
              />{" "}
              Show Outgoing
            </Label>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
}
