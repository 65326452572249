import { ChangeEvent, FormEvent, useState } from 'react';
import { Input, Form, FormGroup, Button, InputGroup, InputGroupAddon, Label, FormText } from 'reactstrap';
import { useWebSocket } from '../../Services/ReactWebSocket';

export default function MessageCreator() {
    const [state, , send] = useWebSocket();
    const [value, updateValue] = useState<string>('');
    
    function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (send) {
            send(value);
            updateValue('');
        }
    }

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        updateValue(e.target.value);
    }


    return <div className="address-bar">
        <Form className="w-100" onSubmit={onSubmit} inline={true}>
            <FormGroup className="w-100">
                <Label>INPUT</Label>
                <FormText>(Write a message to be sent upstream)</FormText>
                <InputGroup className="w-100">
                    <InputGroupAddon addonType="prepend">
                        <Input disabled={!state} type="select" className="rounded-0 rounded-left">
                            <option>text</option>
                        </Input> 
                    </InputGroupAddon>
                    <Input value={value} disabled={!state} type="text" onChange={onChange} placeholder="Write something" />
                    <InputGroupAddon addonType="append">
                        <Button disabled={!state} color="success">Send</Button>
                    </InputGroupAddon>
                </InputGroup>
                { !state && <FormText>To unlock this panel, please connect to a websocket.</FormText> }
            </FormGroup>
        </Form>
    </div>    
}