import { EventEmitter } from "events";
import { IWSState, WebSocketReadyState } from "./IWSState";

export type WSDataType =
  | string
  | SharedArrayBuffer
  | ArrayBuffer
  | ArrayBufferView
  | Blob;

// potential todo: Use Chrome API to make this more powerful
export default class WebSocketWrapper extends EventEmitter implements IWSState {
  private readonly ws: WebSocket;
  constructor(public url: string, public protocols?: string | string[]) {
    // Todo: use proxy here?
    super();
    this.ws = new WebSocket(url, protocols);
    this.ws.addEventListener("open", this.onOpen);
    this.ws.addEventListener("error", this.onError);
    this.ws.addEventListener("close", this.onClose);
    this.ws.addEventListener("message", this.onMessage);
  }

  get readyState(): WebSocketReadyState {
    return this.ws.readyState;
  }

  get bufferedAmount() {
    return this.ws.bufferedAmount;
  }

  get binaryType(): BinaryType {
    return this.ws.binaryType;
  }

  get extensions(): string | undefined {
    return this.ws.extensions;
  }

  get protocol(): string {
    return this.ws.protocol;
  }

  private readonly onOpen = () => {
    this.emit("readyStateChanged", this);
    this.emit("open", this.state);
  };

  private readonly onClose = (ev: CloseEvent) => {
    this.emit("readyStateChanged", this);
    this.emit("close", {
        code: ev.code,
        reason: ev.reason
    });
  };

  private readonly onMessage = (e: MessageEvent) => {
    this.emit("data", e.data);
  };

  private readonly onError = (err: Event) => {
    this.emit("readyStateChanged", this);
    this.emit("error", new Error("Error websocket was closed"));
  };

  public send(data: WSDataType) {
    this.ws.send(data);
  }

  get state(): IWSState {
    return {
      binaryType: this.binaryType,
      bufferedAmount: this.bufferedAmount,
      protocol: this.protocol,
      readyState: this.readyState,
      url: this.url,
      extensions: this.extensions,
    };
  }

  public close(code: number, reason: string = 'closed by user') {
    this.ws.close(code, reason);
  }
}
