import React, { PropsWithChildren } from "react";
import { IWSMessageHistoryElement } from "../../Services/WebSocketManager";

type PropType = PropsWithChildren<IWSMessageHistoryElement>;
export default function Message(props: PropType) {
  if (props.type === "message") {
    return (
      <div className="px-2 my-1">
        <div
          className={(props.direction === "in" ? "text-primary" : "text-success") + " border-bottom"}
        >
          {
            props.direction === "out" ? "↑" : "↓"
          }: {props.data}
        </div>
      </div>
    );
  }

  if (props.type === "connect") {
    return (
      <div className="px-2 my-1">
        <div
          className="text-info border-bottom"
        >
          x: Opening a connection to {props.url}
        </div>
      </div>
    )
  }

  if (props.type === "disconnect") {
    return (
      <div className="px-2 my-1">
        <div
          className="text-danger border-bottom"
        >
          x: Closing the connection to {props.url}
        </div>
      </div>
    )
  }

  if (props.type === "error") {
    return (
      <div className="px-2 my-1">
        <div
          className="text-error border-bottom"
        >
          x: Error on socket {props.url}
        </div>
      </div>
    )
  }

  if (props.type === "open") {
    return (
      <div className="px-2 my-1">
        <div
          className="text-success border-bottom"
        >
          x: Connected to {props.url}
        </div>
      </div>
    )
  }

  if (props.type === "close") {
    return (
      <div className="px-2 my-1">
        <div
          className="text-success border-bottom"
        >
          x: Connection closed {props.code} {props.reason}
        </div>
      </div>
    )
  }

  return <div>{
    JSON.stringify(props)
  }</div>;
}
