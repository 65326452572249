import React from "react";
import { Jumbotron, Container, Row, Col } from "reactstrap";
import AddressBar from "../AddressBar/AddressBar";
import MessageCreator from "../MessageCreator/MessageCreator";
import MessageViewer from "../MessageViewer/MessageViewer";
import MetadataViewer from "../MetadataViewer/MetadataViewer";

export function WSDebugger() {
  return (
    <div>
      <Jumbotron className="rounded-0">
        <Container className="text-center">
          <h1 className="display-3">wscat.dev</h1>
          <p className="lead">
            https://wscat.dev is an browser tool for debugging websockets,
            inspired by{" "}
            <a href="https://www.npmjs.com/package/wscat">wscat-cli</a> and{" "}
            <a href="https://jwt.io">jwt.io</a>. Built by <a href="https://kanalo.dev">Kanalo.dev</a>
          </p>
        </Container>
      </Jumbotron>
      <div className="wsdev-app">
        <Container className="my-5">
          <Row>
            <Col>
              <AddressBar />
            </Col>
          </Row>
        </Container>
        <div>
          <Container className="my-4">
            <MessageCreator />
          </Container>
          <Container className="my-4">
            <Row>
              <Col lg="8">
                <MessageViewer />
              </Col>
              <Col lg="4">
                <MetadataViewer />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
