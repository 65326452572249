export enum WebSocketReadyState {
    CONNECTING = WebSocket.CONNECTING,
    OPEN = WebSocket.OPEN,
    CLOSING = WebSocket.CLOSING,
    CLOSED = WebSocket.CLOSED
}

export interface IWSState {
    readyState: WebSocketReadyState;
    binaryType: BinaryType;
    bufferedAmount: number;
    extensions?: string;
    protocol: string;
    url: string;
}