import { Label } from "reactstrap";
import { useWebSocket } from "../../Services/ReactWebSocket";

export default function MetadataViewer() {
  const [ws] = useWebSocket();

  return (
    <div>
      <Label>WEBSOCKET</Label>
      {!ws ? (
        <div>Connnect to see this</div>
      ) : (
        <div className="border border-gray rounded p-3">
          <pre>
            <code className="text-wrap-pre">{JSON.stringify(ws, null, 4)}</code>
          </pre>
        </div>
      )}
    </div>
  );
}
