import "./App.css";
import {
  WebSocketManagerProvider,
  WebSocketProvider,
} from "../Services/ReactWebSocket";
import { WSDebugger } from "../Components/WSDebugger/WSDebugger";
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="home">
      <WebSocketManagerProvider>
        <WebSocketProvider>
          <WSDebugger />
        </WebSocketProvider>
      </WebSocketManagerProvider>
      <Analytics />
    </div>
  );
}

export default App;
