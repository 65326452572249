import { EventEmitter } from "events";
import { IWSState } from "./IWSState";
import WebSocket, { WSDataType } from "./WebSocketWrapper";

export interface IWSMessage {
  type: "message";
  direction: "out" | "in";
  data: WSDataType;
  timestamp: number;
}

export interface IWSCloseMessage {
  type: "close";
  code: number;
  reason: string;
  timestamp: number;
}

export interface IWSLifeCycleMessage {
  type: "connect" | "open" | "error" | "disconnect";
  url: string;
  timestamp: number;
}

export type IWSMetaMessage = IWSLifeCycleMessage | IWSCloseMessage;
export type IWSMessageHistoryElement = IWSMessage | IWSMetaMessage;
export type IWSMessageHistory = IWSMessageHistoryElement[];
export default class WebSocketManager extends EventEmitter {
  public ws: null | WebSocket = null;
  private interval: any | null = null;
  private history: IWSMessageHistory = [];

  connect(url: string, protocol?: string) {
    if (this.hasSocket) {
      throw new Error("Already connecected");
    }

    this.ws = new WebSocket(url, protocol);
    this.ws.on("data", this.onData);
    this.ws.on("close", this.onClose);
    this.ws.on("error", this.onError);
    this.ws.on("open", this.onOpen);

    this.emitState();
    this.addToHistory({
      type: "connect",
      url: url,
      timestamp: Date.now(),
    });
    this.interval = setInterval(this.poll, 1000);
  }

  disconnect() {
    if (!this.hasSocket) {
      throw new Error("No websocket");
    }
    const url = this.ws!.url;
    this.ws!.close(3000);

    this.cleanUpWs();
    this.emitState();
    this.addToHistory({
      type: "disconnect",
      timestamp: Date.now(),
      url,
    });
  }

  private onOpen = () => {
    const url = this.ws!.url;
    this.addToHistory({
      type: "open",
      timestamp: Date.now(),
      url,
    });
  };

  private onError = () => {
    const url = this.ws!.url;
    this.addToHistory({
      type: "error",
      timestamp: Date.now(),
      url,
    });
  };

  private onClose = ({ code, reason }: any) => {
    this.addToHistory({
      type: "close",
      timestamp: Date.now(),
      code,
      reason,
    });
  };

  private cleanUpWs() {
    if (!this.hasSocket) {
      return;
    }
    clearInterval(this.interval);
    this.ws!.off("data", this.onData);
    this.ws!.off("open", this.onData);
    this.ws!.off("close", this.onClose);
    this.ws!.off("error", this.onError);
    this.ws = null;
    this.emitState();
  }

  public send = (payload: WSDataType) => {
    if (this.hasSocket === false) {
      throw new Error("Cant send message without a socket");
    }
    this.addToHistory({
      direction: "out",
      type: "message",
      data: payload,
      timestamp: Date.now(),
    });
    // Dunno why the ! is needed
    this.ws!.send(payload);
  };

  get hasSocket() {
    return !!this.ws;
  }

  private getSocketState(): IWSState | null {
    if (!this.ws) {
      return null;
    }
    return this.ws.state;
  }

  private poll = () => {
    this.emitState();
  };

  private onData = (data: WSDataType) => {
    this.addToHistory({
      type: "message",
      direction: "in",
      data: data,
      timestamp: Date.now(),
    });
  };

  private addToHistory(element: IWSMessageHistoryElement) {
    this.history.unshift(element);
    this.emitHistory();
  }

  private emitHistory() {
    this.emit("history", [...this.history]);
  }

  private emitState() {
    this.emit("state", this.getSocketState());
  }
}
