import { createContext, PropsWithChildren, useContext, useState } from "react";
import { IWSState } from "./IWSState";
import WebSocketManager, { IWSMessageHistory } from "./WebSocketManager";
import { WSDataType } from "./WebSocketWrapper";

const singleton = new WebSocketManager();
const WebSocketManagerContext = createContext<WebSocketManager>(singleton);
const WebSocketContext = createContext<
  [IWSState | null, IWSMessageHistory, ((message: WSDataType) => void) | null]
>([null, [], null]);

export function useWebSocketManager() {
  return useContext(WebSocketManagerContext);
}

export function WebSocketManagerProvider({ children }: PropsWithChildren<{}>) {
  return (
    <WebSocketManagerContext.Provider value={singleton}>
      {children}
    </WebSocketManagerContext.Provider>
  );
}

export function useWebSocket() {
  return useContext(WebSocketContext);
}

export function WebSocketProvider({ children }: PropsWithChildren<{}>) {
  const manager = useWebSocketManager();
  const [wsState, updateWSState] = useState<IWSState | null>(null);
  const [wsHistory, updateWSHistory] = useState<IWSMessageHistory>([]);

  manager.on("state", (state) => updateWSState(state));
  manager.on("history", (history) => updateWSHistory(history));

  return (
    <WebSocketContext.Provider value={[wsState, wsHistory, manager.send]}>
      {children}
    </WebSocketContext.Provider>
  );
}
