import { FormEvent } from "react";
import {
  Input,
  Form,
  FormGroup,
  Button,
  InputGroup,
  InputGroupAddon,
  Label,
  FormText,
} from "reactstrap";
import { WebSocketReadyState } from "../../Services/IWSState";
import { useWebSocket, useWebSocketManager } from "../../Services/ReactWebSocket";

export default function AddressBar() {
  const wsManager = useWebSocketManager();
  const [ws] = useWebSocket();

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (ws) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Do you wish to close the socket?")) {
        wsManager.disconnect();
        return;
      }
    }

    const formData = new FormData(event.target as HTMLFormElement);
    const url = formData.get("url");

    // There must be an easier way to do this...
    if (url instanceof File) {
      return;
    }

    if (url === null) {
      return;
    }

    wsManager.connect(url);
    localStorage.url = url;
  }

  let button =  <Button color="success">Connect</Button>;

  if (ws?.readyState === WebSocketReadyState.CONNECTING) {
    button = <Button color="success" disabled>Connecting...</Button>
  }

  if (ws?.readyState === WebSocketReadyState.CLOSING) {
    button = <Button color="danger" disabled>Disconnecting...</Button>
  }

  if (ws?.readyState === WebSocketReadyState.OPEN) {
    button = <Button color="danger">Disconnect</Button>
  }

  const searchParams = new URLSearchParams(window.location.hash.substring(1));
  const urlFromHash = searchParams.get('url');

  return (
    <div className="address-bar">
      <Form className="w-100" onSubmit={onSubmit} inline={true}>
        <FormGroup className="w-100">
          <Label>URL</Label>
          <InputGroup className="w-100" size="lg">
            <Input
              /**
               * We will pick the order
               * 1. Take from URL
               * 2. Take from LocalStorage
               * 3. Default
               */
              defaultValue={urlFromHash || localStorage.url || "wss://echo.on.kanalo.dev/demo"}
              type="text"
              name="url"
              id="url"
              placeholder="A websocket url like: wss://echo.on.kanalo.dev/demo"
            />
            <InputGroupAddon addonType="append">
             {button}
            </InputGroupAddon>
          </InputGroup>
          <FormText>You can use the URL for any websocket server you'd like to connect to here.</FormText>
        </FormGroup>
      </Form>
    </div>
  );
}
